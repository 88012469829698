body {
  margin: 0;
  padding: 0;
font-family: 'Inter','Montserrat','Poppins','Oswald','Merriweather', 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*{
  box-sizing: border-box;
}



@keyframes bounceIn {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  60% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes bounceIn {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  60% {
    transform: scale(1.2) rotate(90deg);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}

div#load {
  animation : bounceIn 2s;
  -webkit-animation: bounceIn 2s ease-in-out;
  width: 150px;
  height: 150px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    border-radius:0;
  }
  100% {
    opacity: 1;
    border-radius:50px;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    border-radius:0;
  }
  100% {
    opacity: 1;
    border-radius:50px;
  }
}

@keyframes rotateR {
  from {
    transform: rotate(0deg);
    
  }
  to {
    transform: rotate(360deg);
    
  }
}

@-webkit-keyframes rotateR {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

div#SqCo {
  text-align:center;
  width: 100%;
  height: 150px;
  padding-top: 34.5px;
}

div#SqCo .bloc{
  background:#616e80;
  display:inline-block;
  width:50px;
  height:50px;
  margin: 0 10px;
  opacity:0;
}

div#SqCo .bloc.one {
  -webkit-animation: fadeIn 1s linear 0s infinite alternate,
                    rotateR 1s linear 0s infinite;
  animation: fadeIn 1s linear 0s infinite alternate,
              rotateR 1s linear 0s infinite;
}
div#SqCo .bloc.two {
  -webkit-animation: fadeIn 1s linear 0.5s infinite alternate,
              rotateR 1s linear 0.5s infinite;
  animation: fadeIn 1s linear 0.5s infinite alternate,
              rotateR 1s linear 0.5s infinite;
}
div#SqCo .bloc.three {
  -webkit-animation: fadeIn 1s linear 1s infinite alternate,
              rotateR 1s linear 1s infinite;
  animation: fadeIn 1s linear 1s infinite alternate,
              rotateR 1s linear 1s infinite;
}
div#SqCo .bloc.four {
  -webkit-animation: fadeIn 1s linear 1.5s infinite alternate,
              rotateR 1s linear 1.5s infinite;
  animation: fadeIn 1s linear 1.5s infinite alternate,
              rotateR 1s linear 1.5s infinite;
}
div#SqCo .bloc.five {
  -webkit-animation: fadeIn 1s linear 2s infinite alternate,
              rotateR 1s linear 2s infinite;
  animation: fadeIn 1s linear 2s infinite alternate,
              rotateR 1s linear 2s infinite;
}

div#SqCo p{
  color:#616e80;
  font-size:26px;
  font-family:"farray";
  margin-bottom:10px;
}

div#SqCo p .point{
  opacity:0;
}

div#SqCo p .point.one {
  -webkit-animation: fadeIn 1s linear 0s infinite alternate;
  animation: fadeIn 1s linear 0s infinite alternate;
}
div#SqCo p .point.two {
  -webkit-animation: fadeIn 1s linear 0.5s infinite alternate;
  animation: fadeIn 1s linear 0.5s infinite alternate;
}
div#SqCo p .point.three {
  -webkit-animation: fadeIn 1s linear 1s infinite alternate;
  animation: fadeIn 1s linear 1s infinite alternate;
}
/*EyeCo*/
div#EyeCo {
  text-align:center;
  background-color:#3498db;
  width: 100%;
  height: 170px;
  padding-top: 34.5px;
}
div#face{
  opacity:0;
}
div#EyeCo .paupiere{
  background:black;
  display:inline-block;
  width:50px;
  height:50px;
  margin: 0 ;
  border-radius:100% 100% 0 0;
}
div#EyeCo .eye{
  background:white;
  display:inline-block;
  width:50px;
  height:50px;
  margin: 0 10px;
  border-radius:100% 100% 0 0;
}
div#EyeCo .eye .pupil {
  background:black;
  display:inline-block;
  width:35px;
  height:35px;
  margin:15px 10px;
  border-radius:100% 100% 0 0;
  -webkit-border-radius:100% 100% 0 0;
  -moz-border-radius:100% 100% 0 0;
}
div#EyeCo .eye .pupil .iris {
  background:white;
  display:inline-block;
  width:15px;
  height:15px;
  margin:15px 10px;
  border-radius:100%;
  -webkit-border-radius:100%;
  -moz-border-radius:100%;
}
div#EyeCo .eye .pupil .iris .reflet{
  background:black;
  display:inline-block;
  width:5px;
  height:5px;
  margin:5px;
  border-radius:100%;
  -webkit-border-radius:100%;
  -moz-border-radius:100%;
}
div#EyeCo .nose {
  display:block;
  font-size:30px;
  transform:rotate(-90deg);
  -webkit-transform:rotate(-90deg);
  -moz-transform:rotate(-90deg);
}
@keyframes moveIn {
  0% {
    opacity:0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes moveIn {
  0% {
    opacity:0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes moveEyes {
  0% {
    margin-left:10px;
  }
  5% {
    margin-left:10px;
  }
  35% {
    margin-left:20px;
  }
  40% {
    margin-left:-10px;
  }
  70% {
    margin-left:-10px;
  }
  90% {
    margin-left:10px;
  }
}

@-webkit-keyframes moveEyes {
  0% {
    margin-left:10px;
  }
  30% {
    margin-left:20px;
  }
  60% {
    margin-left:-10px;
  }
  90% {
    margin-left:10px;
  }
  100% {
    margin-left:10px;
  }
}

div#face {
  -webkit-animation: moveIn 1s linear 1s 1 forwards;
  animation: moveIn 1s linear 1s 1 forwards;
}
div#EyeCo .eye .iris {
  -webkit-animation: moveEyes 3s linear 2s infinite;
  animation: moveEyes 3s linear 2s infinite;
}